<mat-card appearance="outlined" *ngIf="recipe" tabindex="0" class="recipe-card">
  <mat-card-header>
    <div class="recipe-name-container">
      <div class="recipe-name">{{ recipe.name }}</div>
      <div class="recipe-type-chip">
        <mat-chip-listbox disabled small *ngIf="!isMobile">
          <mat-chip-option disabled *ngIf="recipeService.resolveRecipeChip(recipe) as chipConfig">
            <mat-icon class="icon" svgIcon="{{ chipConfig.icon }}"></mat-icon>
            {{ chipConfig.i18n | translate | uppercase }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
    <app-category-recipe-card-pricing-information
      class="pricing-information"
      [recipe]="recipe"
    >
    </app-category-recipe-card-pricing-information>
    <app-category-recipe-card-triple-dot-menu
      class="tripledot-container"
      [recipe]="recipe"
      [isMobile]="isMobile"
      (deleteRecipeClicked)="deleteRecipeClicked.emit($event)"
    >
    </app-category-recipe-card-triple-dot-menu>
  </mat-card-header>
</mat-card>
