import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';
import { ErrorModalComponent } from '@gfs/shared-components';
import { IGlobalDialogsService } from '@gfs/shared-services';

@Injectable({
  providedIn: 'root',
})
export class GlobalDialogsService implements IGlobalDialogsService {
  constructor(private matDialog: MatDialog) {}

  private getActiveLoadingDialog() {
    return this.matDialog.getDialogById(InventoryConstants.LOADING_MODAL_ID);
  }

  // TODO: parameterize this so we can open this modal with various errors
  private getResetErrorModal() {
    return this.matDialog.getDialogById(InventoryConstants.ERROR_MODAL_ID);
  }

  public closeLoadingModal() {
    const dialog = this.getActiveLoadingDialog();
    if (dialog) {
      dialog.close();
    }
  }

  public closeResetErrorModal() {
    const dialog = this.getResetErrorModal();
    if (dialog) {
      dialog.close();
    }
  }

  // TODO: parameterize this so we can open this modal with various errors
  public openResetErrorModal(): MatDialogRef<ErrorModalComponent> {
    return this.matDialog.open(ErrorModalComponent, {
      id: InventoryConstants.ERROR_MODAL_ID,
      panelClass: 'error-modal',
      maxWidth: 'unset',
      data: {
          subtitle: 'MESSAGES.OPEN_WORKSHEET_EXISTS',
          cancelButtonLabel: 'MESSAGES.OKAY',
          title:'MESSAGES.OPEN_WORKSHEET_EXISTS_TITLE',
      },
    });
  }
}
