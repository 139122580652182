import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomerPK } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { Observable, tap } from 'rxjs';
import { saveAs as saveAsFn } from 'file-saver-es';

@Injectable({
  providedIn: 'root'
})
export class RecipeReportService {
  private baseUrl: string;
  private httpOptions = {
    responseType: 'arraybuffer' as 'arraybuffer',
    headers: new HttpHeaders({
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/json'
    })
  };
  saveAs = saveAsFn;
  constructor(
    @Inject(InjectionTokens.API_BASE_URL) private API_BASE_URL: string,
    private http: HttpClient,

  ) {
    this.baseUrl = API_BASE_URL + '/api/v1';
   }

   downloadRecipeSummaryReport(
    locale:string,
    customerPk:CustomerPK,
    customerName:string
  ):Observable<any>{
    const params = {
      ...customerPk,
      locale,
      customerName
    };
     return this.http.get(`${this.baseUrl}/reports/recipe-summary-value`,
      { ...this.httpOptions, params }
    ).pipe(tap(response => this.downloadRecipeSummaryReportExcel(response)));
  }

  public downloadRecipeSummaryReportExcel(response){
    const blob = new Blob([response]);
    this.saveAs(blob, `RecipeSummary.xlsx`);
  }
  
}
