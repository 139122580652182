<ng-container *ngIf="vm$ | async as vm">
  {{firstLoadComplete$.next(true)}}
  <div *ngIf="vm.ingredientForm as form" style="width:100%">
    <div class="card-body" [formGroup]="form">
      <gfs-inventory-item-card [isMobile$]="isMobile$" [currentLanguage]="currentLanguage$" [itemData]="vm.displayItem"
        [uiIndex]="1" [displayItemPrices]="false" [hasIngredientPurchasePrice]="vm.hasIngredientPurchasePrice">
      </gfs-inventory-item-card>
      <div class="button-containers" *ngIf="vm.displayItem?.itemReference?.type !== 'RECIPE'">
        <div class="configure-buttons">
          <div 
          *ngIf="(vm.displayItem.itemReference?.type === 'GFS') && (applicationUserRole !== 'Employee')" 
          class="add-to-cart-icon" 
          (keydown)="addToCart(vm)" 
          (click)="addToCart(vm)"
          [ngClass]="{'disabled' : (cartService.disbaleSubject | async).disable 
          && (cartService.disbaleSubject | async).itemId === form.value.itemId }"
          >
            <mat-icon  [matTooltip]="'RECIPE.CREATE.ADD_TO_CART' | translate" matTooltipClass="recipe-icon-tooltip">add_shopping_cart</mat-icon>
          </div>
          <div class="add-to-cart-icon" (keydown)="openItemEditor.emit()" (click)="openItemEditor.emit()">
            <div class="new-element-label">
              <mat-icon [matTooltip]="'RECIPE.CREATE.EDIT_INGREDIENTS' | translate" matTooltipClass="recipe-icon-tooltip">edit</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="ingredient-form">
        <div>
          <input mat-input class="number-input" type="text" formControlName="quantity" #qtyInput tight
            appearance="outline"
            (keypress)="checkForDecimalsAndFractionsInputGate($event, qtyInput.value)"
            (paste)="validateMeasurementUnitQuantity($event)" (click)="$event.target.select()" />
        </div>
        <div class="new-recipe-batch-info-inputs">
          <mat-form-field [ngClass]="{ 'invalid-unit-form': vm.hasIncompleteForm }">
            <mat-label>
              {{
              'RECIPE.CREATE.SELECT_UNIT_OF_MEASURE' | translate
              }}
            </mat-label>

            <input matInput #autoCompleteInput type="text" class="number-input" matAutocompletePosition="below" [matAutocomplete]="auto1" #trigger="matAutocompleteTrigger"  [value]="selectedUnitName" formcontrolname="unit" />
            <mat-icon matSuffix class="mat-autocomplete-arrow" svgIcon="arrow-icon"></mat-icon>
            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="getOptionDisplayText"
              (optionSelected)="unitTypeSelected($event, form)">
              <ng-container *ngFor="let unitGroup of vm.units">
                <ng-container *ngIf="!unitGroup.name">
                  <mat-option *ngFor="let option of unitGroup.options" [value]="option">
                    {{option.name}}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="!!unitGroup.name">
                  <mat-optgroup  label="{{
                  unitGroup.name | translate | titlecase
                }}">
                <div class="Info-text" style="font-size: 14px; font-weight: 400; background-color: white; display: flex; align-items: center;" *ngIf="!(unitGroup?.options?.length !== 0)">
                  <mat-icon matSuffix class="warning-icon" svgIcon="warning-icon">
                  </mat-icon>
                  <span class="warning-icon-configure">{{
                    'RECIPE.CREATE.CONFIGURE_UNITS_WARNING' | translate
                    }}</span>
                </div>
                <mat-option *ngFor="let option of unitGroup.options" [value]="option">
                  {{option.name}}
                </mat-option>
                  </mat-optgroup>
                </ng-container>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error *ngIf="form.hasError('qtyRequired') || form.controls.quantity.hasError('min')">
            {{ 'RECIPE.CREATE.SELECT_QUANTITY' | translate }}
          </mat-error>
          <mat-error *ngIf="form.hasError('unitRequired')">
            {{ 'RECIPE.CREATE.SELECT_UNIT_OF_MEASURE' | translate }}
          </mat-error>
        </div>
        <div class="warning-wrapper">
          <div class="warning-tooltip" matTooltip="{{ 'RECIPE.CREATE.MISSING_INFO' | translate }}"
            attr.aria-label="{{ 'RECIPE.CREATE.MISSING_INFO' | translate }}" [hidden]="!vm.showWarningIndicator">
            <mat-icon matSuffix class="warning-icon" svgIcon="warning-icon">
            </mat-icon>
          </div>
        </div>
        <div style="min-width: 130px;">
          <span>
            {{ 'RECIPE.CREATE.PORTION_PRICE' | translate }}:
            <ng-container >
                <gfs-localized-currency-value [value]="form.value.portionPrice" *ngIf="form.value.portionPrice && form.value.calculationState !== 'INPROGRESS'">
                </gfs-localized-currency-value>
                <mat-spinner *ngIf="form.value.calculationState === 'INPROGRESS'" [diameter]="20" [strokeWidth]="3" class="progress-spinner"></mat-spinner>
            </ng-container>
          </span>
        </div>
      </div>
      <div class="ingredient-footer">
        <button mat-button type="button" color="warn" (click)="removeIngredient.emit(form)">
          x {{ 'RECIPE.CREATE.REMOVE_INGREDIENT' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!firstLoadComplete">
  <div class="loadingcontainer">
    <mat-spinner [diameter]="70" [strokeWidth]="7" class="progress-spinner"></mat-spinner>
  </div>
</ng-container>
