import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RecipeConstants } from '@gfs/constants';
import {
  APPLICATION_USER_ROLE,
  AddToCartPayload,
  ApplicationUserRole,
  IAppContext, ItemReference,
  LocalizedMeasurementUnitOptionGroupx,
  ResolvedItem
} from '@gfs/shared-models';
import { InjectionTokens, unitGr } from '@gfs/shared-services';
import { firstValueFrom, isTruthy, pushToSubject, tapLog } from '@gfs/shared-services/extensions/rxjs';
import { getOptionDisplayTextFn, showConfigureButtonFn } from 'libs/shared-components/src/v2/form/measurement-unit-option.service';
import { checkForDecimalsAndFractionsInputGateFn } from 'libs/shared-components/src/v2/form/v2.form.module';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, scan, startWith, takeUntil, tap, toArray, withLatestFrom ,filter} from 'rxjs/operators';
import { CalculationState, RecipeEditContainerFactory } from '../edit.component.store';
import { recipeFormControlFeelConfig } from '../form-factory.service';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';
import { ActionTypes, AddToCartAttempt, GetCartDataSuccess } from '@gfs/store/feature/cart/actions';
import { ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '@gfs/store/feature/cart-service/cart.service';
import { AnalyticsService } from '@gfs/store/feature/analytics-service/analytics-service.service';

@Component({
  selector: 'app-edit-ingredient-card-v2',
  templateUrl: './edit-ingredient.component.html',
  styleUrls: ['./edit-ingredient.component.scss']
})
export class EditIngredientCardV2Component implements OnInit, OnDestroy {
 

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) public appCtx: IAppContext,
    private containerFactory: RecipeEditContainerFactory,
    public overlay: LoadingSpinnerOverlayService,
    private store: Store<AppState>,
    @Inject(APPLICATION_USER_ROLE) public applicationUserRole : ApplicationUserRole,
    private translate: TranslateService,
    public cartService: CartService,
    private analyticsService : AnalyticsService

  ) {
  }

  @Input()
  public set ingredientForm(value: UntypedFormGroup) {
    this.form$.next(value);
  }
  isMobile$ = this.appCtx.isMobile;
  currentLanguage$ = this.appCtx.language;
  selectedUnitName = null;
  container$ = this.containerFactory.instance$;
  form$ = new BehaviorSubject<UntypedFormGroup>(null);
  firstLoadComplete$ = new BehaviorSubject<boolean>(false);
  firstLoadComplete = false;

  vm$ = new Subject<any>();
  destroy$ = new Subject<void>();

  @Input()
  uiIndex: number;

  unitDisplayConfiguration = unitGr;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  @Output()
  openItemEditor = new EventEmitter<void>();

  @Output()
  optionSelected = new EventEmitter<any>();

  @Output() removeIngredient = new EventEmitter<UntypedFormGroup>();

  @Input()
  enableLaunchItemEditor: boolean;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  checkForDecimalsAndFractionsInputGate = checkForDecimalsAndFractionsInputGateFn;
  showConfigureButtonInternal = showConfigureButtonFn;

  getOptionDisplayText = getOptionDisplayTextFn;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit(): Promise<any> {
   this.cartService.handleAddToCartSuccess()
    window.addEventListener('scroll', this.scrollEvent, true);
    WorksheetEffects.itemUpdateInProgress$.pipe(tap(()=>{setTimeout(()=>{this.autocompleteTrigger.closePanel()},100)})).subscribe();

    const itemReference$ = this.createItemReferenceFromForm$(this.form$);
    const componentVM$ = this.createVM$(this.form$);

    this.initComponentEffects$(itemReference$, componentVM$)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe();

    return firstValueFrom(this.vm$).toPromise();
  }

  initComponentEffects$(
    itemReference$: Observable<ItemReference>,
    componentVM$: Observable<any>
  ) {
    return combineLatest([
      this.createBehaviorLiveTranslateSelectedUOM$(this.appCtx.language, itemReference$),
      this.createBehaviorUseComponentVM$(componentVM$),
      this.createBehaviorStopSpinnerOnFirstLoad$(this.firstLoadComplete$),
    ]);
  }
  scrollEvent = (event: any): void => {
    if(this.autoComplete.panelOpen){
      this.autoComplete.updatePosition();
    }
};
  private createBehaviorStopSpinnerOnFirstLoad$(notifier: Subject<boolean>) {
    return notifier
      .pipe(
        isTruthy(),
        tap(() => this.firstLoadComplete = true)
      );
  }

  private createBehaviorUseComponentVM$(vmSource$: Observable<any>) {
    return vmSource$
      .pipe(
        pushToSubject(this.vm$)
      );
  }

  unitTypeSelected(
    $event,
    form: UntypedFormGroup
  ) {
    if (!$event.option.value) {
      return;
    }
    form.markAsDirty();
    form.controls.unitType.setValue($event.option.value.key);

  }
  showConfigureButton(optionGroup: LocalizedMeasurementUnitOptionGroupx) {
    if (this.enableLaunchItemEditor) {
      return this.showConfigureButtonInternal(optionGroup);
    }
    return false;
  }
  addToCart(Vm){
    this.cartService.disbaleSubject.next({disable : true,itemId : Vm.ingredientForm.value.itemId})
    const addToCartRequest = {
      itemId : Vm.ingredientForm.value.itemId,
      quantity : Vm.ingredientForm.value.quantity, //1, 
      unitType : Vm.ingredientForm.value.unitType, //'CS' 
    } as AddToCartPayload
    this.store.dispatch(new AddToCartAttempt({addToCartData : addToCartRequest}));
    this.analyticsService.GA4EventLogger('recipe_add_to_cart' ,  Vm.ingredientForm.value.itemId)
    
  }

  createIncompleteFormNotifier$(form: UntypedFormGroup): Observable<boolean> {
    return form.statusChanges
      .pipe(
        startWith(false),
        concatMap(() =>
          from([
            'unitRequired',
            'qtyRequired',
            'qtyInvalid'
          ]).pipe(
            map(errorKey => form.hasError(errorKey)),
            toArray(),
            map(results => !(results.indexOf(true) === -1)),
          )
        )
      );
  }

  getUnitOfMeasureSelectedOptionText$(
    unitTypeKey: string,
    itemReference$: Observable<ItemReference>
  ) {
    return firstValueFrom(
      this.container$.pipe(
        withLatestFrom(itemReference$),
        concatMap(([container, itemReference]) =>
          firstValueFrom(
            container.createLocalizedMeasurementUnitLookup$(itemReference, { useDisplayItem: true })
          )
        ),
        map(lookup => {
          return lookup[unitTypeKey] ?? null;
        }),
      ));
  }

  private createDisplayItemReference$(itemRef$: Observable<ItemReference>): Observable<ResolvedItem> {
    return itemRef$
      .pipe(
        withLatestFrom(this.container$),
        concatMap(([itemRef, container]) => container.getItemData$(itemRef, { useDisplayItem: true }))
      );
  }

  private createItemReferenceFromForm$(form$: Observable<UntypedFormGroup>): Observable<ItemReference> {
    return form$
      .pipe(
        isTruthy(),
        withLatestFrom(this.container$),
        concatMap(([{ value: { itemType, itemId } }, container]) => container.getItemData$({ type: itemType, key: itemId })),
        map(h => h.itemReference)
      );
  }

  private createLocalizedMeasurementUnits$(
    displayItemData$: Observable<ResolvedItem>
  ): Observable<LocalizedMeasurementUnitOptionGroupx[]> {
    return displayItemData$
      .pipe(
        withLatestFrom(this.container$),
        concatMap(([{ itemReference }, container]) =>
          container.getLocalizedMeasurementUnits$(itemReference, { useDisplayItem: true })
        ),
        tap(()=>{this.overlay.hide()})
      );
  }

  private createBehaviorLiveTranslateSelectedUOM$(
    lang$: Observable<string>,
    itemRef$: Observable<ItemReference>
  ) {
    return lang$.pipe(
      withLatestFrom(this.form$),
      concatMap(([, f]) =>
        this.getUnitOfMeasureSelectedOptionText$(f.value.unitType, itemRef$)
      ),
      tap((selectedOption) => {
        this.selectedUnitName = this.getOptionDisplayText(selectedOption);
      })
    );
  }

  private createCalculationInProgressNotifier$(form: UntypedFormGroup) {
    return form.controls.calculationState.valueChanges
      .pipe(
        startWith(false),
        distinctUntilChanged(),
        map((calculationState) => calculationState === CalculationState.CalculationInProgress),
      );
  }

  private createHasCompletedOneCalculationCycleNotifier$(form: UntypedFormGroup): Observable<boolean> {
    return form.valueChanges
      .pipe(
        startWith({ hasCompletedOneCalculation: false }),
        distinctUntilChanged(),
        scan((acc, state) => acc || state.hasCompletedOneCalculation, false),
      );
  }

  private createHasPurchasePriceNotifier$(form: UntypedFormGroup): Observable<boolean> {
    return form.controls.purchasePrice.valueChanges
      .pipe(
        startWith(null),
        map(v => !!v),
        distinctUntilChanged()
      );
  }

  private createStalePriceNotifier$(
    form: UntypedFormGroup,
    isIncompleteForm$: Observable<boolean>,
  ) {
    return combineLatest([
      form.controls.calculationState.valueChanges
        .pipe(
          startWith(form.controls.calculationState.value),
          map((calculationState) =>
            calculationState === CalculationState.CalculationPending
            || calculationState === CalculationState.CalculationInProgress
          ),
          distinctUntilChanged(),
        ),
      form.statusChanges
        .pipe(
          startWith({}),
          map(() => form.hasError('unableToComplete')
          ),
          distinctUntilChanged()
        ),
      isIncompleteForm$,
    ])
      .pipe(
        map(x => x.indexOf(true) > -1)
      );
  }

  private createWarningIndicatorNotifier$(
    form: UntypedFormGroup,
    isIncompleteForm$: Observable<boolean>,
    isInProgress$: Observable<boolean>,
  ): Observable<boolean> {
    return combineLatest([
      isIncompleteForm$,
      isInProgress$,
      form.statusChanges
        .pipe(
          startWith(form.status),
          map(e => {
            return !!form.getError('unableToComplete');
          }),
          distinctUntilChanged(),
        )
    ]).pipe(
      map(([inComplete, inProgress, isUnableToComplete]) => {
        return isUnableToComplete || (!inProgress && inComplete);
      })
    );
  }

  /**
   * if input contains invalid characters, do not allow user to paste
   *
   * @param event a paste event
   */
  validateMeasurementUnitQuantity(event): void {
    const value = (
      event.clipboardData || (window as any).clipboardData
    ).getData('Text');
    if (value && !value.match(RecipeConstants.BATCH_YIELD_QTY_REGEX)) {
      event.preventDefault();
    }
  }

  createVM$(form$: Observable<UntypedFormGroup>) {
    return form$.pipe(
      concatMap(form => {

        const formItemRef$ = this.createItemReferenceFromForm$(form$);
        const displayItemRef$ = this.createDisplayItemReference$(formItemRef$);
        const incompleteFormNotifier$ = this.createIncompleteFormNotifier$(form);
        const calculationInProgressNotifier$ = this.createCalculationInProgressNotifier$(form);


        const hasIngredientPurchasePrice$ = combineLatest([
          this.createHasCompletedOneCalculationCycleNotifier$(form)
            .pipe(
              tap(r => {
                console.log('testF');
              })
            ),
          this.createHasPurchasePriceNotifier$(form)
            .pipe(
              tap(r => {
                console.log('testF');
              })
            )
        ]).pipe(
          map(([oneCycleCompleted, hasPurchasePrice]) =>
            oneCycleCompleted && !hasPurchasePrice
          ),
          map(hasCompletedOneCycleAndDoesntHavePurchasePrice =>
            !hasCompletedOneCycleAndDoesntHavePurchasePrice
          )
        );
        return combineLatest([
          form$,
          displayItemRef$,
          this.createLocalizedMeasurementUnits$(displayItemRef$),
          this.createStalePriceNotifier$(form, incompleteFormNotifier$),
          calculationInProgressNotifier$,
          this.createWarningIndicatorNotifier$(form, incompleteFormNotifier$, calculationInProgressNotifier$),
          incompleteFormNotifier$,
          hasIngredientPurchasePrice$
        ]).pipe(
          debounceTime(recipeFormControlFeelConfig.generalUIDebounce),
          tapLog('Updating VM$'),
          map(([
            ingredientForm,
            displayItem,
            units,
            isStalePrice,
            calculationInProgress,
            showWarningIndicator,
            hasIncompleteForm,
            hasIngredientPurchasePrice
          ]) => {
            return ({
              ingredientForm,
              displayItem,
              units,
              isStalePrice,
              calculationInProgress,
              showWarningIndicator,
              hasIncompleteForm,
              hasIngredientPurchasePrice
            });
          })
        );
      }
      ),
    );
  }
}
