import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecipeConstants } from '@gfs/constants';
import { QuickAddItemsModule, SharedComponentsModule } from '@gfs/shared-components';
import { TranslateModule } from '@ngx-translate/core';
import { PipeSharingModule } from '@recipe-ui/pipes/pipe-sharing-module/pipe-sharing.module';
import { RecipeIconService } from '@recipe-ui/services/images/recipe-icon.service';
import { MaterialModule } from '../material.module';
import { BatchYieldHelpDialogComponent } from './batch-yield-help-dialog/batch-yield-help-dialog.component';
import { RecipeImageManagerComponent } from './create-recipe/recipe-image-manager/recipe-image-manager.component';
import { RecipeCancelConfirmComponent } from './recipe-cancel-confirm/recipe-cancel-confirm.component';
import { RecipePricingPanelComponent } from './recipe-pricing-panel/recipe-pricing-panel.component';
import { RecipeComponent } from './recipe/recipe.component';
import { SaveBarComponent } from './save-bar/save-bar.component';
import { PrintableRecipeComponent } from './printable-recipe/printable-recipe.component';
import { PrintRecipeDialogComponent } from './print-recipe-dialog/print-recipe-dialog.component';
import { SharedDirectivesModule } from '@gfs/directives';
import { UIModule } from '@recipe-ui/ui/ui.module';
import { ItemDataService } from '@gfs/shared-services';
import { LocalizedUIV2Module } from '@gfs/v2/shared-components';
const { icons } = RecipeConstants;

@NgModule({
  declarations: [
    RecipeComponent,
    RecipeImageManagerComponent,
    RecipeCancelConfirmComponent,
    RecipePricingPanelComponent,
    SaveBarComponent,
    BatchYieldHelpDialogComponent,
    PrintableRecipeComponent,
    PrintRecipeDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    PipeSharingModule,
    TranslateModule.forChild(),
    SharedComponentsModule,
    QuickAddItemsModule,
    SharedDirectivesModule,
    UIModule,
    LocalizedUIV2Module,
  ],
  exports: [
    RecipeComponent,
    RecipeImageManagerComponent,
    RecipeCancelConfirmComponent,
    RecipePricingPanelComponent,
    SaveBarComponent,
    BatchYieldHelpDialogComponent,
    PrintableRecipeComponent,
    PrintRecipeDialogComponent,
  ],
  providers: [
    ItemDataService,
  ]
})
export class RecipeModule {
  readonly recipeIcons = [
    icons.warning
  ];

  constructor(public recipeIconService: RecipeIconService) {
    recipeIconService.registerIcons(this.recipeIcons);
  }
}
