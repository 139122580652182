import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Recipe, RecipeCategory } from '@gfs/shared-models';
import { AppState, selectAllCategories } from '@gfs/store/recipe/reducers';
import {
  CloneRecipeAttempt,
  PatchRecipeAttempt,
} from '@gfs/store/recipe/actions/recipe.actions';
import { RecipeConstants } from '@gfs/constants';
import { RecipeEffects } from '@gfs/store/recipe/effects/recipe.effects';

export interface DialogData {
  recipeName: string;
  destinationCategory: string;
}

@Component({
  selector: 'app-category-recipe-card-triple-dot-menu',
  templateUrl: './category-recipe-card-triple-dot-menu.component.html',
  styleUrls: ['./category-recipe-card-triple-dot-menu.component.scss'],
})
export class CategoryRecipeCardTripleDotMenuComponent {
  @Input() recipe: Recipe;
  @Input() isMobile: boolean;

  @Output()
  deleteRecipeClicked: EventEmitter<Recipe> = new EventEmitter<Recipe>();
  @Output()
  editRecipeClicked: EventEmitter<Recipe> = new EventEmitter<Recipe>();

  categories$: Observable<RecipeCategory[]> = this.store.pipe(
    select(selectAllCategories)
  );
  duplicateCompleted$ = RecipeEffects.duplicateCompleted$

  constructor(private store: Store<AppState>) {}

  moveRecipeToCategoryHandler(recipe: Recipe, category?: RecipeCategory): void {
    recipe.categoryId = category?.id ?? null;
    this.store.dispatch(
      new PatchRecipeAttempt({
        recipe,
        analyticsActionName: 'moveRecipeToCategory',
      })
    );
  }

  cloneRecipeHandler(recipeId: string): void {
    RecipeEffects.duplicateCompleted$.next(true)
    this.store.dispatch(
      new CloneRecipeAttempt(
        recipeId,
        RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER
      )
    );
  }
}
